import React, { Component } from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

class RadioSelect extends Component {
  state = {}

  handleChange = optionSelected => {
    const { onChange } = this.props

    onChange && onChange(optionSelected)
  }

  render() {
    const {
      optionsText,
      optionOne,
      optionTwo,
      optionThree,
      optionFour,
      name
    } = this.props

    return (
      <div className={style.radioSelectContainer}>
        <div className={style.optionsText}>{optionsText}</div>

        <div className={style.radio}>
          <input
            id={`option1-${name}`}
            name={name}
            type="radio"
            value="option1"
            onChange={() => this.handleChange(optionOne)}
          />
          <label htmlFor={`option1-${name}`}>{optionOne}</label>
        </div>

        <div className={style.radio}>
          <input
            id={`option2-${name}`}
            name={name}
            type="radio"
            value="option2"
            onChange={() => this.handleChange(optionTwo)}
          />
          <label htmlFor={`option2-${name}`}>{optionTwo}</label>
        </div>

        {optionThree && (<div className={style.radio}>
          <input
            id={`option3-${name}`}
            name={name}
            type="radio"
            value="option3"
            onChange={() => this.handleChange(optionThree)}
          />
          <label htmlFor={`option3-${name}`}>{optionThree}</label>
        </div>
        )}

        {optionFour && (
          <div className={style.radio}>
            <input
              id={`option4-${name}`}
              name={name}
              type="radio"
              value="option4"
              onChange={() => this.handleChange(optionFour)}
            />
            <label htmlFor={`option4-${name}`}>{optionFour}</label>
          </div>
        )}
      </div>
    )
  }
}

RadioSelect.propTypes = {
  optionsText: PropTypes.string,
  optionOne: PropTypes.string,
  optionTwo: PropTypes.string,
  optionThree: PropTypes.string,
  optionFour: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func
}

RadioSelect.defaultProps = {
  optionsText: '',
  optionOne: '',
  optionTwo: '',
  optionThree: '',
  optionFour: '',
  name: '',
  onChange: null
}

export default RadioSelect
