class SheetAPI{
  save_project_submission(body){
      return fetch(`https://x5z3i2bqd0.execute-api.sa-east-1.amazonaws.com/staging/spreadsheet/ProjectSubmission`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'ZjxyiwNrFLa5O9DAgMwym1KevEag3MOn1FpSw9Cw'

      },
      body: JSON.stringify(body),
      });
  }
  save_consult_submission(body){
      return fetch(`https://x5z3i2bqd0.execute-api.sa-east-1.amazonaws.com/staging/spreadsheet/ConsultSubmission`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'ZjxyiwNrFLa5O9DAgMwym1KevEag3MOn1FpSw9Cw'
      },
      body: JSON.stringify(body),
      });
  }
}
export default new SheetAPI();