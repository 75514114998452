import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectContactForm from '../components/ProjectContactForm'

import '../styles/global.scss'

const ProjectForm = () => (
  <Layout>
    <SEO title="Contato" keywords={[`gatsby`, `application`, `react`]} />
    <ProjectContactForm />
  </Layout>
)

export default ProjectForm
