import SheetAPI from './sheet';

class API {
  
    sheet = SheetAPI;
  
  
  }
  
  export default new API();
  