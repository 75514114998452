import React from 'react'
import style from '../style.module.scss'

export default [
  {
    number: 2,
    type: 'RadioSelect',
    formInput: 'roomsQuantity',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.radioSelect} ${style.clickActive}`,
    optionsText: 'Escolha a quantidade de ambientes',
    optionOne: '1 ambiente: entre R$4.000,00 e R$8.000,00',
    optionTwo: '2 a 4 ambientes: entre R$8.000,00 e R$16.000,00',
    optionThree: '5 a 7 ambientes: entre R$16.000,00 e R$25.000,00',
    optionFour: '8 ou mais ambientes: entre R$25.000,00 a R$35.000,00',
    descriptionTextClassName: style.descriptionText,
    descriptionText: (
      <span>
        O valor do seu projeto vai depender do grau de intervenção que faremos
        na sua casa. Quanto maior a interferência, maior a complexidade do
        projeto.
      </span>
    )
  },
  {
    number: 3,
    type: 'textInput',
    formInput: 'name',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Qual seu nome?',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 4,
    type: 'textInput',
    formInput: 'email',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Qual o seu email?',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 5,
    type: 'textInput',
    formInput: 'phone',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Qual seu Whatsapp?',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 6,
    type: 'textInput',
    formInput: 'city',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Qual é a sua cidade/estado?',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 7,
    type: 'textInput',
    formInput: 'description',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.textInput} ${style.clickActive}`,
    questionText: 'Forneça uma breve descrição dos ambientes',
    placeholder: 'Digite sua resposta aqui...'
  },
  {
    number: 8,
    type: 'submit',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.submitSection} ${style.clickActive}`
  }
]
