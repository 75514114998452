import React, { Component } from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

class TwoOptionsSelect extends Component {
  constructor(props) {
    super(props)

    const { buttonsColors } = this.props

    this.cssVariables = {
      '--selected-color': buttonsColors.selected,
      '--hover-selected-color': buttonsColors.hoverSelected,
      '--unselected-color': buttonsColors.unselected,
      '--hover-unselected-color': buttonsColors.hoverUnselected
    }
  }

  state = {
    optionOneColor: style.selected,
    optionTwoColor: style.selected
  }

  handleChange = optionSelected => {
    const { onChange, optionOne, optionTwo } = this.props

    onChange && onChange(optionSelected)

    if (optionSelected === optionOne) {
      return this.setState({
        optionOneColor: style.selected,
        optionTwoColor: style.unselected
      })
    }

    if (optionSelected === optionTwo) {
      return this.setState({
        optionOneColor: style.unselected,
        optionTwoColor: style.selected
      })
    }
  }

  render() {
    const { questionText, optionOne, optionTwo } = this.props
    const { optionOneColor, optionTwoColor } = this.state

    return (
      <div className={style.twoOptionsSelectContainer}>
        <div className={style.questionText}>{questionText}</div>

        <div className={style.options}>
          <button
            className={`${style.optionButton} ${optionOneColor}`}
            type="button"
            style={this.cssVariables}
            onClick={() => this.handleChange(optionOne)}
          >
            {optionOne}
          </button>

          <button
            className={`${style.optionButton} ${optionTwoColor}`}
            type="button"
            onClick={() => this.handleChange(optionTwo)}
            style={this.cssVariables}
          >
            {optionTwo}
          </button>
        </div>
      </div>
    )
  }
}

TwoOptionsSelect.propTypes = {
  questionText: PropTypes.string,
  optionOne: PropTypes.string,
  optionTwo: PropTypes.string,
  onChange: PropTypes.func,
  buttonsColors: PropTypes.shape({
    selected: PropTypes.string,
    hoverSelected: PropTypes.string,
    unselected: PropTypes.string,
    hoverUnselected: PropTypes.string
  })
}

TwoOptionsSelect.defaultProps = {
  questionText: '',
  optionOne: '',
  optionTwo: '',
  onChange: null,
  buttonsColors: {
    selected: '',
    hoverSelected: '',
    unselected: '',
    hoverUnselected: ''
  }
}

export default TwoOptionsSelect
