import React from 'react'

const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <path
      fill="#B9BFC8"
      fillRule="evenodd"
      d="M17.41 1.59c.932.932 1.446 2.267 1.528 3.972.041.722.062 2.048.062 3.938 0 1.891-.021 3.216-.062 3.938-.082 1.705-.596 3.04-1.528 3.972-.932.932-2.267 1.446-3.973 1.528-.721.041-2.047.062-3.937.062-1.891 0-3.216-.021-3.938-.062-1.705-.082-3.04-.596-3.972-1.528-.932-.932-1.446-2.267-1.528-3.972C.021 12.716 0 11.391 0 9.5c0-1.89.021-3.216.062-3.938.082-1.705.596-3.04 1.528-3.972C2.522.658 3.857.144 5.562.062 6.292.021 7.617 0 9.5 0s3.208.021 3.938.062c1.705.082 3.04.596 3.972 1.528zm-2.813 1.717c-.3 0-.56.109-.775.321a1.062 1.062 0 0 0-.321.775c0 .3.109.561.321.774.214.214.475.322.775.322.3 0 .561-.108.775-.322a1.06 1.06 0 0 0 .32-.774c0-.301-.107-.56-.32-.775a1.064 1.064 0 0 0-.775-.321zm-2.813 8.477a3.126 3.126 0 0 1-2.284.946 3.126 3.126 0 0 1-2.284-.946A3.126 3.126 0 0 1 6.27 9.5c0-.887.318-1.655.946-2.284A3.126 3.126 0 0 1 9.5 6.27c.887 0 1.655.318 2.284.946.628.629.946 1.397.946 2.284 0 .887-.318 1.656-.946 2.284zM9.5 4.649c-1.34 0-2.495.476-3.435 1.416S4.649 8.16 4.649 9.5c0 1.34.476 2.495 1.416 3.435.94.939 2.094 1.415 3.435 1.415 1.34 0 2.496-.476 3.435-1.415.939-.94 1.415-2.095 1.415-3.435 0-1.34-.476-2.495-1.415-3.435-.94-.94-2.096-1.416-3.435-1.416z"
    />
  </svg>
)

const LinkedinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 18 17"
  >
    <path
      fill="#B9BFC8"
      fillRule="evenodd"
      d="M3.895 5.324v11.06H.212V5.324h3.683zm.234-3.416c.012 1.06-.792 1.909-2.075 1.909H2.03C.792 3.817 0 2.969 0 1.908 0 .826.826 0 2.076 0c1.261 0 2.042.826 2.053 1.908zM9.6 6.896c.484-.758 1.358-1.829 3.314-1.829 2.422 0 4.23 1.585 4.23 4.978v6.339H13.47v-5.915c0-1.485-.536-2.5-1.864-2.5-1.015 0-1.618.68-1.886 1.339-.09.246-.123.57-.123.904v6.172H5.926c.045-10.022 0-11.06 0-11.06h3.672v1.572z"
    />
  </svg>
)

const PortfolioIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <g
      fill="#B9BFC8"
      fillRule="nonzero"
      stroke="#B9BFC8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".7"
    >
      <path d="M6.02 7.18l.002.002 5.797 5.797v.001a.82.82 0 0 0 1.162-1.16l-5.8-5.8A.82.82 0 1 0 6.02 7.18z" />
      <path d="M2.54 9.5a4.911 4.911 0 0 0 4.75 1.27L5.81 9.29a3.257 3.257 0 0 1-2.11-.95A3.281 3.281 0 0 1 8.34 3.7a3.28 3.28 0 0 1 .504 3.983l1.184 1.184A4.918 4.918 0 0 0 2.54 2.54a4.922 4.922 0 0 0 0 6.96z" />
      <path d="M6.02 10.94c1.822 0 3.408-.99 4.258-2.461H8.185c-.578.51-1.333.82-2.164.82a3.281 3.281 0 0 1 0-6.562 3.28 3.28 0 0 1 3.173 2.46h1.675a4.918 4.918 0 0 0-9.77.82A4.922 4.922 0 0 0 6.02 10.94zM16.46 9.5a4.91 4.91 0 0 0-4.75-1.27l1.48 1.48a3.257 3.257 0 0 1 2.11.95 3.282 3.282 0 0 1-4.64 4.64 3.28 3.28 0 0 1-.504-3.984l-1.184-1.184a4.92 4.92 0 0 0 7.488 6.328 4.922 4.922 0 0 0 0-6.96z" />
      <path d="M13.237 8.067a4.91 4.91 0 0 0-4.38 2.234l2.09.11a3.257 3.257 0 0 1 2.204-.706 3.282 3.282 0 0 1-.344 6.554 3.28 3.28 0 0 1-3.04-2.624l-1.673-.088a4.92 4.92 0 0 0 9.8-.308 4.922 4.922 0 0 0-4.657-5.172z" />
    </g>
  </svg>
)

const YoutubeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="19"
    viewBox="0 0 22 19"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M14.025 9.908a.511.511 0 0 1-.177.18l-4.256 2.659a.217.217 0 0 1-.11.06.533.533 0 0 1-.245 0 .556.556 0 0 1-.119-.046.47.47 0 0 1-.17-.174.477.477 0 0 1-.065-.248v-5.33a.48.48 0 0 1 .065-.25.478.478 0 0 1 .17-.173.396.396 0 0 1 .244-.068c.084.005.16.027.23.068l4.256 2.672c.078.04.138.099.177.174a.505.505 0 0 1 0 .476zm7.287-1.977c-.027-.743-.1-1.535-.218-2.35a14.575 14.575 0 0 0-.522-2.314c-.233-.73-.559-1.276-.968-1.62-.158-.134-.389-.263-.707-.394-.313-.132-.79-.25-1.458-.365-.657-.113-1.535-.207-2.615-.278C13.738.537 12.346.5 10.687.5c-3.642 0-5.895.131-6.89.4-1.222.332-1.61.504-1.721.591-.477.319-.852.882-1.126 1.678a14.77 14.77 0 0 0-.617 2.47 21.346 21.346 0 0 0-.26 2.43L0 9.663c.014.357.041.918.083 1.667.041.75.132 1.535.271 2.334.138.801.342 1.568.607 2.278.267.722.635 1.258 1.094 1.592.175.11.748.297 1.743.566.994.269 3.247.4 6.889.4 1.659 0 3.051-.037 4.137-.11 1.084-.071 1.965-.165 2.615-.278.668-.114 1.145-.233 1.458-.363.323-.135.55-.254.717-.379.385-.338.704-.854.935-1.526.23-.662.406-1.384.525-2.145.116-.76.194-1.52.228-2.261.035-.74.06-1.346.073-1.833l-.063-1.674z"
    />
  </svg>
)

const PinterestIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      fill="#B9BFC8"
      fillRule="evenodd"
      d="M17.143 8.571a8.574 8.574 0 0 1-8.572 8.572 8.387 8.387 0 0 1-2.433-.357c.324-.514.692-1.172.87-1.83 0 0 .101-.38.604-2.356.29.57 1.16 1.072 2.087 1.072 2.756 0 4.631-2.511 4.631-5.882 0-2.533-2.154-4.91-5.435-4.91-4.062 0-6.116 2.924-6.116 5.357 0 1.473.558 2.79 1.752 3.28.19.079.369 0 .424-.222.045-.145.134-.525.179-.681.056-.223.033-.29-.123-.48-.346-.413-.569-.938-.569-1.685 0-2.166 1.618-4.107 4.219-4.107 2.299 0 3.571 1.406 3.571 3.292 0 2.466-1.094 4.554-2.723 4.554-.893 0-1.563-.737-1.35-1.652.256-1.083.758-2.255.758-3.036 0-.703-.379-1.295-1.16-1.295-.915 0-1.652.949-1.652 2.221 0 0 0 .815.279 1.362-.938 3.973-1.105 4.665-1.105 4.665-.156.647-.167 1.373-.145 1.976A8.574 8.574 0 0 1 8.572 0a8.574 8.574 0 0 1 8.57 8.571z"
    />
  </svg>
)
// whastapp icon
const WhatsappIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    {...props}
  >
  <path d="M30.3672 5.62477C28.7589 4.00836 26.8433 2.72675 24.732 1.85469C22.6207 0.982635 20.3561 0.53759 18.0701 0.545514C8.49208 0.545514 0.685794 8.31277 0.685794 17.8429C0.685794 20.8975 1.49274 23.8647 3.00137 26.4829L0.545456 35.4545L9.75512 33.0458C12.2987 34.4247 15.1581 35.1578 18.0701 35.1578C27.6482 35.1578 35.4545 27.3905 35.4545 17.8604C35.4545 13.2349 33.6476 8.88877 30.3672 5.62477ZM18.0701 32.2254C15.4739 32.2254 12.9303 31.5273 10.7024 30.2182L10.1761 29.904L4.70296 31.3353L6.15896 26.0291L5.80812 25.488C4.3657 23.1962 3.5998 20.5471 3.5978 17.8429C3.5978 9.91858 10.0884 3.46042 18.0526 3.46042C21.9119 3.46042 25.5431 4.9615 28.2622 7.68441C29.6085 9.01787 30.6754 10.604 31.4011 12.3507C32.1269 14.0975 32.4969 15.9702 32.4898 17.8604C32.5249 25.7847 26.0343 32.2254 18.0701 32.2254ZM25.9992 21.4735C25.5606 21.264 23.4205 20.2167 23.0346 20.0596C22.6311 19.92 22.3504 19.8502 22.0522 20.2691C21.754 20.7055 20.9295 21.6829 20.6839 21.9622C20.4383 22.2589 20.1752 22.2938 19.7366 22.0669C19.2981 21.8575 17.8947 21.3862 16.2457 19.92C14.9476 18.768 14.088 17.3542 13.8249 16.9178C13.5793 16.4815 13.7898 16.2546 14.0179 16.0277C14.2108 15.8357 14.4564 15.5215 14.6669 15.2771C14.8774 15.0328 14.9652 14.8408 15.1055 14.5615C15.2458 14.2648 15.1757 14.0204 15.0704 13.8109C14.9652 13.6015 14.088 11.472 13.7372 10.5993C13.3864 9.76149 13.018 9.86622 12.7548 9.84876H11.9128C11.6146 9.84876 11.1585 9.95349 10.755 10.3899C10.3691 10.8262 9.24639 11.8735 9.24639 14.0029C9.24639 16.1324 10.8077 18.192 11.0182 18.4713C11.2287 18.768 14.088 23.1316 18.4385 24.9993C19.4735 25.4531 20.2804 25.7149 20.912 25.9069C21.947 26.2385 22.8942 26.1862 23.6486 26.0814C24.4906 25.9593 26.2273 25.0342 26.5781 24.0218C26.9465 23.0095 26.9465 22.1542 26.8237 21.9622C26.7009 21.7702 26.4378 21.6829 25.9992 21.4735Z" fill="white"/>

  </svg>
)

export {
  InstagramIcon,
  LinkedinIcon,
  PortfolioIcon,
  YoutubeIcon,
  PinterestIcon,
  WhatsappIcon
}
