import React from 'react'
import style from '../style.module.scss'
import onlyArchitectureQuestions from './only_architecture_questions'
import architectureAndInteriorsQuestions from './architecture_and_interiors_questions'

export default [
  {
    number: 1,
    type: 'twoOptionsSelect',
    formInput: 'projectType',
    ref: React.createRef(),
    leftAwnserQuestions: onlyArchitectureQuestions,
    rightAwnserQuestions: architectureAndInteriorsQuestions,
    questionState: style.opacityActive,
    className: `${style.twoOptionsSelect} ${style.clickActive}`,
    optionOne: 'SÓ ARQUITETURA',
    optionTwo: 'ARQUITETURA + INTERIORES',
    descriptionTextClassName: style.descriptionText,
    descriptionText: (
      <p>
        Nossos projetos de construção podem restringir-se à concepção da sua
        casa com todo o necessário para sua obra ser finalizada, ou seja,
        <strong> plantas, cortes, fachadas, detalhamento de áreas
        molhadas (banheiros, cozinha e área de serviço) e maquete
        eletrônica de todo o projeto. </strong>

        <br />
        <br />

        Porém também é possível contratar o serviço do projeto arquitetônico
        com o projeto de interiores de toda a casa, ou seja,
        <strong>tudo que foi citado acima além de todo o detalhamento de salas, quartos,
        áreas de lazer com especificações que vão até o mobiliário.</strong>
      </p>
    )
  }
]
