import React from 'react'
import style from '../style.module.scss'
import apartmentQuestions from './apartment_questions'
import houseQuestions from './house_questions'

export default [
  {
    number: 1,
    type: 'twoOptionsSelect',
    formInput: 'projectType',
    ref: React.createRef(),
    questionState: style.opacityActive,
    className: `${style.twoOptionsSelect} ${style.clickActive}`,
    questionText: 'O que você deseja reformar?',
    optionOne: 'APARTAMENTO',
    optionTwo: 'CASA',
    leftAwnserQuestions: apartmentQuestions,
    rightAwnserQuestions: houseQuestions
  }
]
