import React from 'react'
import style from './style.module.scss'
import reformQuestions from './reform_questions/index'
import constructionQuestions from './construction_questions/index'

export default [
  {
    number: 0,
    type: 'twoOptionsSelect',
    formInput: 'project',
    ref: React.createRef(),
    questionState: '',
    className: style.twoOptionsSelect,
    questionText: 'Que tipo de projeto você quer fazer?',
    optionOne: 'REFORMA',
    optionTwo: 'CONSTRUÇÃO',
    descriptionTextClassName: style.descriptionText,
    descriptionText: (
      <span>
        Vamos precisar de alguns detalhes sobre o seu projeto. Desse jeito a
        gente consegue preparar melhor a nossa primeira conversa. Vamos lá?
      </span>
    ),
    leftAwnserQuestions: reformQuestions,
    rightAwnserQuestions: constructionQuestions
  }
]
