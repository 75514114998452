import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import style from './style.module.scss'
import { WhatsappIcon } from '../SocialIcons'

const MyModal = ({isModalOpen, setIsModalOpen, buttonClassName,...props}) => {
  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
		props.handleClose()
    setIsModalOpen(false)
    window.location = '/'
  }
	

  return (
    <div>
      <button
        // type="submit"
        className={buttonClassName}
        onClick={ (e) => {
          handleOpenModal()
          props.onOpenModal ? props.onOpenModal() : null;
					// e.preventDefault()
				}}
      >
        Enviar
      </button>
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={ () => {
          setIsModalOpen(false);
          window.location = '/'
				}}
        className={style.questionsModalContainer}
        // overlayClassName={style.questionsModalOverlayContainer}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <div className={style.closeIcon}>
          <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={()=>{setIsModalOpen(false); window.location = '/'}}><g data-name="Layer 2"><g data-name="close"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></g></g></svg>
        </div>
        <div className={style.modalContent}>
          <h2>Obrigada!</h2>
          <div className={style.bold}>
            Recebemos os detalhes sobre o seu projeto.
          </div>
          <div className={style.grayText}>
            Em breve vamos entrar em contato e marcar a nossa primeira conversa.
          </div>
          {/* <button onClick={handleCloseModal} className={style.closeButton}>
            {' '}
            <WhatsappIcon style={{ height: '20px', verticalAlign: 'sub' }} />
            FALE CONOSCO PELO WHATSAPP
          </button> */}
        </div>
      </Modal>
    </div>
  )
}

export default MyModal
